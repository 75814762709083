<template>
  <div class="st-bread-crumbs" :class="deviceType" data-t="st-bread-crumbs">
    <div class="breadcrumbs-list" data-t="breadcrumbs-list-xlac">
      <NuxtI18nLink
        v-for="crumb in changedBreadCrumbs"
        :key="crumb.label"
        data-t="crumb-link"
        :to="crumb.link"
        class="crumb-link"
        :class="{ disabled: crumb.disabled }"
        :title="crumb.hasTooltip ? crumb.label : ''"
      >
        <span class="crumb-label">
          {{ crumb.trimmedLabel ?? crumb.label }}
        </span>
        <StIcon
          v-if="type === 'desktop'"
          class="separator"
          name="oval"
          :size="4"
        />
      </NuxtI18nLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { BreadCrumbs } from './types'

const props = withDefaults(
  defineProps<{
    crumbs: BreadCrumbs[]
    type?: 'desktop' | 'mobile'
  }>(),
  {
    type: 'desktop',
  },
)

/**
 * Получает массив объектов и добавляет
 * необязательные свойства labelNew (обрезанный лейбл),
 * hasTooltip (флаг по которому рисуется title)
 * если длинна слова больше 24 символов
 */
const changedBreadCrumbs = computed(() =>
  props.crumbs.map((crumb, index, array) => {
    const isLast = index === array.length - 1
    const wordLength = 24
    if (!isLast && crumb.label.length > wordLength) {
      return {
        ...crumb,
        trimmedLabel: `${crumb.label.substring(0, wordLength)}...`,
        hasTooltip: true,
      }
    }
    return crumb
  }),
)

const deviceType = computed(() => ({
  mobile: props.type === 'mobile',
  desktop: props.type === 'desktop',
}))
</script>
<style scoped>
.breadcrumbs-list {
  display: inline-flex;
  gap: var(--spacing-075);
  font: var(--desktop-text-xs-semibold);
}

.separator {
  color: var(--text-disable);
}

.crumb-label {
  padding: 0 var(--spacing-025);
  border: 2px solid transparent;
  border-radius: var(--border-radius-100);
}

.crumb-link {
  display: flex;
  gap: var(--spacing-100);
  align-items: center;
  justify-content: center;

  color: var(--text-primary);
  text-decoration: none;

  transition: color 0.3s ease;
}

.desktop {
  .crumb-link {
    &:last-child {
      .separator {
        display: none;
      }
    }

    &:hover {
      color: var(--palette-brand-500);

      .separator {
        color: var(--text-disable);
      }
    }

    &:focus {
      .crumb-label {
        /* stylelint-disable */
        border-color: rgba(255, 106, 20, 10%);
        /* stylelint-enable */
      }
    }
  }
}

.mobile {
  .breadcrumbs-list {
    gap: var(--spacing-100);
    font: var(--mobile-text-medium);
  }

  /* stylelint-disable */
  .crumb-link {
    padding: var(--spacing-100) var(--spacing-125);
    background-color: var(--button-gray-default);
    border-radius: var(--border-radius-100);

    &.disabled {
      opacity: var(--palette-disable-layer-opasity);
    }
  }
  /* stylelint-enable */
}
</style>
